<template>
  <v-menu
    :ref="reference"
    v-model="datePickerProps.menu"
    :close-on-content-click="false"
    transition="slide-x-transition"
    offset-y
    :nudge-right="40"
    min-width="290px"
    :return-value.sync="data[reference]"
  >
    <template v-slot:activator="{ on }">
      <custom-text-field
        v-model="data[reference]"
        hide-details
        :label="$t(datePickerProps.label)"
        :prepend-icon="$vuetify.icons.values.dateRange"
        clearable
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="data[reference]"
      hide-details
      outlined
      first-day-of-week="1"
      scrollable
      :landscape="$vuetify.breakpoint.mdAndUp"
      :locale="locale"
    >
      <v-spacer />
      <v-btn
        text
        rounded
        color="primary"
        @click="$emit('close')"
      >
        {{ $t('general.close') }}
      </v-btn>
      <v-btn
        text
        rounded
        color="primary"
        @click="$refs[reference].save(data[reference])"
      >
        {{ $t('general.ok') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'DatePicker',
  components: {
    CustomTextField
  },
  props: {
    datePickerProps: { type: Object, default: () => { return {} } },
    locale: { type: String, default: 'en' },
    reference: { type: String, default: '' },
    data: { type: Object, default: () => { return {} } }
  }
}
</script>
