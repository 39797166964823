<template>
  <v-row>
    <v-col
      cols="12"
      lg="6"
    >
      <custom-autocomplete
        v-model="form.acquisitionPipeline"
        :prepend-icon="$vuetify.icons.values.trendingDown"
        :items="activeWorkspacePipelines"
        item-value="id"
        item-text="name"
        :chips="$vuetify.breakpoint.mdAndUp"
        :deletable-chips="$vuetify.breakpoint.mdAndUp"
        :small-chips="$vuetify.breakpoint.mdAndUp"
        :loading="getPipelinesLoadingStatus"
        hide-details
        :label="$t('acquisition.received.acquisitionPipeline')"
        multiple
        clearable
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <custom-text-field
        v-model="form.name"
        hide-details
        :prepend-icon="$vuetify.icons.values.received"
        :label="$t('acquisition.received.name')"
        clearable
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdAtFromProps"
        :data="form"
        :locale="locale"
        reference="createdAtFrom"
        @close="createdAtFromProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdAtToProps"
        :data="form"
        :locale="locale"
        reference="createdAtTo"
        @close="createdAtToProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="compDateFromProps"
        :data="form"
        :locale="locale"
        reference="compDateFrom"
        @close="compDateFromProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="compDateToProps"
        :data="form"
        :locale="locale"
        reference="compDateTo"
        @close="compDateToProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      class="pb-0 text-subtitle-1 black--text"
    >
      {{ $t('general.status') }}
    </v-col>
    <v-col
      cols="12"
      class="pt-0"
    >
      <v-row>
        <v-checkbox
          v-model="form.status"
          value="error"
          prepend-icon=" "
          hide-details
          :label="$t('general.filters.errors')"
          color="primary"
        />
        <v-checkbox
          v-model="form.status"
          value="success"
          prepend-icon=" "
          hide-details
          :label="$t('general.filters.success')"
          color="primary"
        />
        <v-checkbox
          v-model="form.status"
          value="processing"
          prepend-icon=" "
          hide-details
          :label="$t('general.filters.processing')"
          color="primary"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {
  props,
  getWorkspacePipelines,
  mounted,
  beforeDestroy,
  computedProperties,
  commonMethods
} from '@/mixins/filters'
import DatePicker from './picker/Date'
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'

export default {
  name: 'AcquisitionReceivedFilters',
  components: {
    DatePicker,
    CustomTextField,
    CustomAutocomplete
  },
  mixins: [
    props,
    getWorkspacePipelines,
    mounted,
    beforeDestroy,
    computedProperties,
    commonMethods
  ],
  data () {
    return {
      processingStatuses: [
        { id: 'true', name: this.$t('general.filters.processing') },
        { id: 'false', name: this.$t('general.filters.complete') }
      ],
      form: {
        name: null,
        acquisitionPipeline: null,
        createdAtFrom: null,
        createdAtTo: null,
        compDateFrom: null,
        compDateTo: null,
        status: []
      },
      createdAtFromProps: {
        menu: false,
        label: 'acquisition.received.createdAtFrom'
      },
      createdAtToProps: {
        menu: false,
        label: 'acquisition.received.createdAtTo'
      },
      compDateFromProps: {
        menu: false,
        label: 'acquisition.received.compDateFrom'
      },
      compDateToProps: {
        menu: false,
        label: 'acquisition.received.compDateTo'
      }
    }
  }
}
</script>
